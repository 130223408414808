<!--StorageFileCardItemSkeleton-->
<template>
  <div class="w-full px-16 pt-12 pb-16 bg-surface-variant-2 rounded-xl">
    <div class="flex flex-col gap-8 animate-pulse">
      <div class="flex items-center h-24">
        <s-skeleton class="w-16 h-16 bg-inverse-elevation-5 rounded-sm" />
      </div>
      <s-skeleton class="w-full aspect-[132/96] bg-inverse-elevation-5 rounded-md" />
      <div class="pr-16">
        <s-skeleton class="w-full h-[1.8rem] bg-inverse-elevation-5 rounded-md" />
      </div>
    </div>
  </div>
</template>
