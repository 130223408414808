<!--StorageFileListItem-->
<template>
  <div
    class="relative flex items-center gap-8 w-full py-[.6rem] pl-20 pr-[1rem] rounded-xl has-[:checked]:bg-[var(--stds-glob-color-gray40)]"
    :class="{
      'after:absolute after:inset-0 after:bg-surface-elevation-1 after:opacity-[85%]': disabled
    }"
  >
    <checkbox
      v-model="selectedImageValue"
      :options="{
        size: 'sm',
        align: 'middle',
        class: 'h-20'
      }"
      @update:modelValue="onHandleValue"
    />
    <span class="relative w-36 h-36 aspect-square rounded-md overflow-hidden cursor-pointer">
      <img
        :src="isVideo ? file.thumbnail : file.linkCdn"
        :alt="file.fullFileName"
        class="absolute inset-0 w-full h-full object-cover cursor-pointer"
        @click="openNewLink"
      />
    </span>

    <p class="flex-1 text-md font-medium leading-lg text-on-surface-elevation-2">
      <st-tooltip-text-over position="bottom-start" hasArrow :content="file.fullFileName">
        <span class="break-all line-clamp-1">{{ file.fullFileName }}</span>
      </st-tooltip-text-over>
    </p>

    <span class="shrink-0 w-92 pr-8 text-md leading-lg text-on-surface-elevation-3 text-right">
      {{ file.fileSizeTxt }}
    </span>
    <span class="shrink-0 w-[12.6rem] text-md leading-lg text-on-surface-elevation-3">
      {{ file.createdAt && getDateTimeByLocale(file.createdAt) }}
    </span>
    <s-menu-popup
      v-if="!hiddenDropdown"
      :distance="0"
      :offset="[0, 10]"
      :flipOnUpdate="true"
      :maxWidth="200"
      placement="left-start"
      trigger="click"
      class="shrink-0 inline-flex p-8"
    >
      <template #target>
        <s-icon
          size="3xl"
          icon="ic-v2-navigation-option-vertical-fill"
          class="text-on-surface-elevation-4"
        />
      </template>
      <template #menuItems>
        <div class="mb-8 px-12 pt-12 pb-16 border-solid border-b-1 border-border">
          <p class="text-xs leading-xs text-on-surface-elevation-3">{{ file.fullFileName }}</p>
          <p class="mt-4 text-xs leading-xs text-on-surface-elevation-3">
            <span>{{
              $t('studio.prj_prod_mngmt.storage_upload_done.file_info2') + ' ' + file.fileSizeTxt
            }}
            </span>
          </p>
        </div>
        <s-menu-popup-item
          icon="ic-v2-content-folder-move-line"
          class="min-w-[19.8rem]"
          value="move"
          @click="moveFile"
        >
          {{ $t('studio.prj_prod_mngmt.storage_upload_done.btn_move_to_folder') }}
        </s-menu-popup-item>
        <s-menu-popup-item
          icon="ic-v2-object-download-line"
          class="min-w-[19.8rem]"
          value="download"
          @click="downloadFile"
        >
          {{ $t('studio.prj_prod_mngmt.storage_upload_done.btn_download') }}
        </s-menu-popup-item>
        <s-menu-popup-item
          icon="ic-v2-object-delete-line"
          class="min-w-[19.8rem]"
          value="delete"
          @click="deleteFile"
        >
          {{ $t('studio.prj_prod_mngmt.storage_upload_done.btn_del') }}
        </s-menu-popup-item>
      </template>
    </s-menu-popup>
  </div>
</template>
<script setup lang="ts">
import { ref, toRefs, watch } from 'vue';

import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { MP4_MIME_TYPE, WEBM_MIME_TYPE } from '@/constants/file.const';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import { getDateTimeByLocale } from '@/utils/date.util';

const emits = defineEmits<{
  onChangeCheckbox: [];
  onMoveFile: [data: StorageUploadFileType];
  onDownloadFile: [data: StorageUploadFileType];
  onDeleteFile: [data: StorageUploadFileType];
}>();

const props = defineProps<{
  file: StorageUploadFileType;
  selectValue: boolean;
  hiddenDropdown: boolean;
  disabled?: boolean;
}>();
const { selectValue, file } = toRefs(props);

const isVideo = ref<boolean>(
  file.value.fileType === MP4_MIME_TYPE.split('/')[1] ||
    file.value.fileType === WEBM_MIME_TYPE.split('/')[1]
);
const selectedImageValue = ref(false);
const onHandleValue = () => {
  emits('onChangeCheckbox');
};

const moveFile = () => {
  emits('onMoveFile', file.value);
};

const downloadFile = () => {
  emits('onDownloadFile', file.value);
};

const deleteFile = () => {
  emits('onDeleteFile', file.value);
};

const openNewLink = () => {
  if (isVideo.value) {
    const newWindow = window.open('', '_blank');
    newWindow!.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Video Player</title>
      </head>
      <body style="background: #00000080; display: flex; align-items: center; justify-content: center; height: 99vh">
        <video id="videoPlayer" style="max-width: 90%" width="auto" height="auto" controls autoplay>
          <source src="${file.value.linkCdn}" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </body>
      </html>
    `);
    newWindow!.document.close();
  } else {
    window.open(file.value.linkCdn, '_blank');
  }
};

watch(
  () => selectValue.value,
  (_value: boolean) => {
    selectedImageValue.value = selectValue.value;
  },
  {
    immediate: true
  }
);
</script>
