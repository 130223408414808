import { storeToRefs } from 'pinia';

import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { STORAGE_API_TIMEOUT } from '@/constants/common.const';
import { ApiMethod } from '@/enums/api.enum';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import type {
  StorageUploadDirectoryType,
  StorageUploadFileType
} from '@/types/storage-upload/storage-upload-file.type';
import type {
  GetFileListRequest,
  GetFolderListRequest,
  UploadFileRequestParams
} from '@/types/storage-upload/storage-upload-request.type';
import type {
  GetDirectoryListResponse,
  GetFileListResponse,
  StorageUploadDirectoryResponse,
  StorageUploadFileResponse,
  StorageUsageResponse
} from '@/types/storage-upload/storage-upload-response.type';
import { camelToSnake } from '@/utils/api.util';
import { formatBytes } from '@/utils/file.util';

// All apis: https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+File

// Storage Usage
// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Check+storage+usage
export const checkStorageUsageApi = async (
  projectId: string,
  showLoading: boolean
): Promise<StorageUsageResponse | undefined> => {
  const { data } = await useRequest<StorageUsageResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/usage`,
    {
      method: ApiMethod.Get,
      showLoading
    }
  );

  return data;
};

// Folder/Directory Upload
// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Get+directory+list
export const fetchFolderListApi = async (
  projectId: string,
  params: GetFolderListRequest,
  showLoading: boolean = true
): Promise<GetDirectoryListResponse | undefined> => {
  const { data } = await useRequest<GetDirectoryListResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/directories`,
    {
      params: camelToSnake(params),
      showLoading
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Create+directory
export const createFolderApi = async (
  projectId: string,
  directoryName: string
): Promise<StorageUploadDirectoryType | undefined> => {
  const { data } = await useRequest<StorageUploadDirectoryResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/directories`,
    {
      data: camelToSnake({ directoryName }),
      method: ApiMethod.Post
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Delete+directory
export const deleteFolderApi = async (
  projectId: string,
  directoryNo: number
): Promise<boolean | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/directories/${directoryNo}`,
    {
      data: camelToSnake({ directoryNo }),
      method: ApiMethod.Delete,
      showCommonError: false
    }
  );

  return data?.success;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Update+directory+name
export const updateFolderNameApi = async (
  projectId: string,
  directoryNo: number,
  directoryName: string
): Promise<StorageUploadDirectoryResponse | undefined> => {
  const { data } = await useRequest<StorageUploadDirectoryResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/directories/${directoryNo}`,
    {
      data: camelToSnake({ directoryName }),
      method: ApiMethod.Put,
      showCommonError: false
    }
  );

  return data;
};

// File Upload
// https://wiki.smilegate.net/pages/viewpage.action?pageId=435957081
export const moveFilesToNewDirectoryApi = async (
  projectId: string,
  fileIds: number[],
  targetDirectoryNo: number
): Promise<number | undefined> => {
  const { data } = await useRequest<{ count: number }>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/files/move`,
    {
      data: camelToSnake({ fileIds, targetDirectoryNo }),
      method: ApiMethod.Post,
      showCommonError: false
    }
  );

  return data?.count;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Get+file+list
export const fetchFileListApi = async (
  projectId: string,
  params: GetFileListRequest,
  showLoading: boolean = true
): Promise<GetFileListResponse | undefined> => {
  const { data } = await useRequest<GetFileListResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/files`,
    {
      params: camelToSnake(params),
      showLoading
    }
  );

  if (data) {
    data.contents = data.contents.map((item: StorageUploadFileResponse) => {
      const newItem: StorageUploadFileType = {
        ...item,
        fileSizeTxt: formatBytes(item.fileSize)
      };
      return newItem;
    });
  }
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435957964
export const deleteFilesApi = async (
  projectId: string,
  fileIds: number[]
): Promise<number | undefined> => {
  const { data } = await useRequest<{ count: number }>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/files/bulk-delete`,
    {
      data: camelToSnake({ fileIds }),
      method: ApiMethod.Post,
      showCommonError: false
    }
  );

  return data?.count;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Upload+file
export const uploadFileApiStorage = async (
  projectId: string,
  file: File,
  params?: UploadFileRequestParams
): Promise<StorageUploadFileResponse | undefined> => {
  const storageUploadStore = useStorageUploadStore();
  const { cancelTokenSource } = storeToRefs(storageUploadStore);
  const formData = new FormData();
  formData.append('file', file);
  const showLoading = params?.showGlobalLoading;
  delete params?.showGlobalLoading;
  const { data } = await useRequest<StorageUploadFileResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/files`,
    {
      data: formData,
      params: camelToSnake(params),
      method: ApiMethod.Post,
      cancelToken: cancelTokenSource.value.token,
      showLoading,
      showCommonError: false,
      timeout: STORAGE_API_TIMEOUT,
      cancelAfter: STORAGE_API_TIMEOUT
    }
  );

  return {
    ...data,
    fileSizeTxt: formatBytes(data?.fileSize || 0),
    createdAt: Date.now()
  } as StorageUploadFileResponse;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Confirm+session
export const confirmAllFilesOnSessionApi = async (
  projectId: string,
  sessionId: string
): Promise<boolean | undefined> => {
  const { data } = await useRequest<{ status: boolean }>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/sessions/${sessionId}`,
    {
      method: ApiMethod.Post
    }
  );

  return data?.status || false;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+Project+storage+%7C+Check+file+existing
export const checkFileExistApi = async (
  projectId: string,
  fileIds: string
): Promise<{ status: boolean } | undefined> => {
  const { data } = await useRequest<{ status: boolean }>(
    `${STUDIO_API_URL}/${API_VERSION}/projects/${projectId}/storage/files/check/exist`,
    {
      method: ApiMethod.Get,
      params: camelToSnake({ fileIds }),
      showCommonError: false
    }
  );

  return data;
};
