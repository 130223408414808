<!--StorageFolderList.vue-->
<template>
  <div v-if="!selectedFolder" class="flex items-center gap-8 min-h-36 mb-16 px-4">
    <p v-if="folderList?.length" class="text-xl font-bold leading-lg text-on-surface-elevation-1">
      {{ $t('studio.prj_prod_mngmt.storage_only_folder.title') }}
    </p>
    <em v-if="folderList?.length" class="text-2xl font-medium leading-xl text-brand-primary">
      {{ folderList.length }}
    </em>
    <s-button
      v-if="!isAttachedFile"
      size="sm"
      variant="outline"
      icon="ic-v2-control-add-line"
      class="shrink-0 ml-auto"
      @click="handleCreateFolderItem"
    >
      {{ $t('studio.prj_prod_mngmt.storage_no_folder_file.create_folder_btn') }}
    </s-button>
  </div>

  <div
    v-if="folderList?.length && !selectedFolder"
    class="grid grid-cols-5 grid-flow-row gap-x-[1rem] gap-y-12 mb-40"
  >
    <storage-folder-item
      v-for="folder in folderList"
      :key="folder.directoryNo"
      :folderName="folder.directoryName"
      :hiddenDropdown="isAttachedFile"
      class="col-span-1"
      @click.stop="goToDetailFolder(folder)"
      @onEdit="handleEditFolderItem($event, folder)"
      @onDelete="handleDeleteFolderItem($event, folder)"
    />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import StorageFolderItem from '@/components/storage/storage-folder-item.vue';
import { useApp } from '@/composables/useApp';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import type { StorageUploadDirectoryType } from '@/types/storage-upload/storage-upload-file.type';

defineProps<{
  isAttachedFile?: boolean;
  isImageListEmpty: boolean;
}>();

const app = useApp();
const storageUploadStore = useStorageUploadStore();
const { folderList, selectedFolder } = storeToRefs(storageUploadStore);

const goToDetailFolder = (folder: StorageUploadDirectoryType) => {
  selectedFolder.value = folder;
};

const handleCreateFolderItem = async () => {
  await storageUploadStore.createOrEditFolderItem();
};

const handleEditFolderItem = async (isEdit: boolean, folder?: StorageUploadDirectoryType) => {
  if (isEdit) {
    await storageUploadStore.createOrEditFolderItem(folder);
  }
};

const handleDeleteFolderItem = async (
  isDelete: boolean,
  folderItem: StorageUploadDirectoryType
) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  if (isDelete) {
    await storageUploadStore.deleteFolderItem(folderItem);
    selectedFolder.value = null;
  }
};
</script>
