<!--StorageFileCardItem-->
<template>
  <div
    class="relative flex flex-col gap-8 w-full px-16 pt-12 pb-16 border-solid border-1 border-border bg-[var(--stds-glob-color-gray20)] rounded-xl has-[:checked]:bg-[#E7EEFF]"
    :class="
      disabled
        ? 'after:absolute after:inset-[-.1rem] after:bg-surface-elevation-1 after:opacity-[85%]'
        : 'group'
    "
  >
    <div class="flex justify-between items-center h-24">
      <checkbox
        v-model="selectedImageValue"
        :options="{
          size: 'sm',
          align: 'middle',
          class: 'h-20'
        }"
        @update:modelValue="onHandleValue"
      />
      <s-menu-popup
        v-if="!hiddenDropdown"
        :distance="0"
        :offset="[0, 0]"
        :flipOnUpdate="true"
        :maxWidth="200"
        placement="bottom-end"
        trigger="click"
        class="shrink-0 hidden group-hover:inline-flex -mr-4 p-4"
        :class="toggleFileMenu ? '!inline-flex' : ''"
        @shown="toggleFileMenu = true"
        @hidden="toggleFileMenu = false"
      >
        <template #target>
          <s-icon
            size="xl"
            icon="ic-v2-navigation-option-vertical-fill"
            class="text-on-surface-elevation-4"
          />
        </template>
        <template #menuItems>
          <div class="mb-8 px-12 pt-12 pb-16 border-solid border-b-1 border-border">
            <p class="text-xs leading-xs text-on-surface-elevation-3 text-wrap break-words">
              {{ file.fullFileName }}
            </p>
            <p class="mt-4 text-xs leading-xs text-on-surface-elevation-3">
              <span>{{
                $t('studio.prj_prod_mngmt.storage_upload_done.file_info2') +
                  ' ' +
                  file.fileSizeTxt
              }}
              </span>
            </p>
          </div>
          <s-menu-popup-item
            icon="ic-v2-content-folder-move-line"
            class="min-w-[19.8rem]"
            value="move"
            @click="moveFile"
          >
            {{ $t('studio.prj_prod_mngmt.storage_upload_done.btn_move_to_folder') }}
          </s-menu-popup-item>
          <s-menu-popup-item
            icon="ic-v2-object-download-line"
            class="min-w-[19.8rem]"
            value="download"
            @click="downloadFile"
          >
            {{ $t('studio.prj_prod_mngmt.storage_upload_done.btn_download') }}
          </s-menu-popup-item>
          <s-menu-popup-item
            icon="ic-v2-object-delete-line"
            class="min-w-[19.8rem]"
            value="delete"
            @click="deleteFile"
          >
            {{ $t('studio.prj_prod_mngmt.storage_upload_done.btn_del') }}
          </s-menu-popup-item>
        </template>
      </s-menu-popup>
    </div>
    <div
      class="relative w-full aspect-[132/96] rounded-md overflow-hidden cursor-pointer"
      @click="openNewLink"
    >
      <div class="absolute inset-0 flex items-center justify-center w-full h-full">
        <img
          :src="isGif || isVideo ? file.thumbnail : file.linkCdn"
          :alt="file.fullFileName"
          class="w-auto h-auto max-w-full max-h-full rounded-md"
          :class="isGif || isVideo ? 'object-cover' : 'object-contain'"
        />
      </div>
      <s-icon
        v-if="isVideo"
        size="3xl"
        icon="ic-v2-media-av-play-round-fill"
        class="absolute bottom-[.6rem] right-[.6rem] text-white before:relative before:z-[1] after:absolute after:inset-12 after:rounded-full after:bg-black/40 drop-shadow-[0_0_0.3rem_rgba(0,0,0,0.5)]"
      />
    </div>
    <st-tooltip-text-over
      v-if="hasEllipsis"
      forceShow
      position="bottom-start"
      hasArrow
      :content="file.fullFileName"
    >
      <safe-html
        class="flex items-center break-all text-2xs leading-xs text-on-surface-elevation-3"
        :html="convertShortFileNameWithContainerWidth(file.fullFileName, 150)"
      />
    </st-tooltip-text-over>
    <safe-html
      v-else
      class="flex items-center break-all text-2xs leading-xs text-on-surface-elevation-3"
      :html="convertShortFileNameWithContainerWidth(file.fullFileName, 150)"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { GIF_MIME_TYPE, MP4_MIME_TYPE, WEBM_MIME_TYPE } from '@/constants/file.const';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import { convertShortFileNameWithContainerWidth } from '@/utils/string.util';

const emits = defineEmits<{
  onChangeCheckbox: [];
  onMoveFile: [data: StorageUploadFileType];
  onDownloadFile: [data: StorageUploadFileType];
  onDeleteFile: [data: StorageUploadFileType];
}>();

const props = defineProps<{
  file: StorageUploadFileType;
  selectValue: boolean;
  hiddenDropdown: boolean;
  disabled?: boolean;
}>();
const { selectValue, file } = toRefs(props);

const hasEllipsis = computed(() => {
  return props.file.fullFileName.length > 24;
});

const isGif = ref<boolean>(file.value.fileType === GIF_MIME_TYPE.split('/')[1]);
const isVideo = ref<boolean>(
  file.value.fileType === MP4_MIME_TYPE.split('/')[1] ||
    file.value.fileType === WEBM_MIME_TYPE.split('/')[1]
);

const toggleFileMenu = ref(false);
const selectedImageValue = ref(false);
const onHandleValue = () => {
  emits('onChangeCheckbox');
};

const moveFile = () => {
  emits('onMoveFile', file.value);
};

const downloadFile = () => {
  emits('onDownloadFile', file.value);
};

const deleteFile = () => {
  emits('onDeleteFile', file.value);
};

const openNewLink = () => {
  if (isVideo.value) {
    const newWindow = window.open('', '_blank');
    newWindow!.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Video Player</title>
      </head>
      <body style="background: #00000080; display: flex; align-items: center; justify-content: center; height: 99vh">
        <video id="videoPlayer" style="max-width: 90%" width="auto" height="auto" controls autoplay>
          <source src="${file.value.linkCdn}" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </body>
      </html>
    `);
    newWindow!.document.close();
  } else {
    window.open(file.value.linkCdn, '_blank');
  }
};

watch(
  () => selectValue.value,
  (_value: boolean) => {
    selectedImageValue.value = selectValue.value;
  },
  {
    immediate: true
  }
);
</script>
