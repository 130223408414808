<!--StoragePopupMoveFolder-->
<template>
  <!-- 폴더 이동 팝업 -->
  <s-dialog to="storage-popup-move-folder" size="sm" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="!w-300 !min-h-[24.6rem] !max-h-[42rem]">
      <st-dialog-header @clickClose="emits('close')">
        {{ $t('studio.prj_prod_mngmt.storage_upload_done.popup_move_to_folder_title') }}
      </st-dialog-header>
      <s-dialog-content class="!p-0 !m-0">
        <s-dialog-content-body class="!p-0">
          <div class="flex flex-col">
            <radio-group v-model="selectedListFolder" :options="optionsListFolder">
              <template #default="{ value, label, index }">
                <div
                  class="flex items-center gap-8 px-20 py-8 has-[:checked]:bg-interaction-selected"
                >
                  <s-icon
                    size="3xl"
                    icon="ic-v2-content-folder-line"
                    class="shrink-0 text-on-surface-elevation-1"
                    @click="handleChooseFolder(value)"
                  />
                  <s-radio
                    :id="`radio-${index}`"
                    :key="index"
                    :value="value"
                    size="sm"
                    align="middle"
                    class="line-clamp-1 flex-1 inline-flex [&>div]:w-full [&>div>input]:hidden [&>div>label]:w-full [&>div>label]:ml-0 [&>div>label]:pr-[.1rem] [&>div>label]:line-clamp-1"
                    :class="{
                      '[&>div>label]:text-brand-primary':
                        currentFolder?.directoryNo.toString() === value
                    }"
                    @click="handleChooseFolder(value)"
                  >
                    <s-tooltip
                      :content="label"
                      :duration="0"
                      :distance="0"
                      useFlip
                      flipOnUpdate
                      placement="bottom-start"
                      trigger="mouseenter focus"
                      :allowHTML="true"
                      :zIndex="2501"
                    >
                      <template #target>
                        <p class="flex-1 line-clamp-1">
                          {{ label }}
                        </p>
                      </template>
                    </s-tooltip>
                  </s-radio>
                </div>
              </template>
            </radio-group>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button
          class="w-full"
          :isDisabled="selectedListFolder === undefined || isSameCurrentFolder"
          @click="onMoveFolder"
        >
          {{ $t('studio.prj_prod_mngmt.storage_upload_done.btn_move') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="storage-popup-move-folder" />
</template>
<script setup lang="ts">
import { ref } from 'vue';

import StDialogHeader from '@/components/common/st-dialog-header.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import type { FormOption } from '@/types/common/form.type';
import type { StorageUploadDirectoryType } from '@/types/storage-upload/storage-upload-file.type';

const props = defineProps<{
  folderList: StorageUploadDirectoryType[];
  currentFolder?: StorageUploadDirectoryType;
}>();

const emits = defineEmits<{
  close: [data?: StorageUploadDirectoryType | null];
}>();

const selectedListFolder = ref<string>();
const selectedMoveFolder = ref<StorageUploadDirectoryType>();
const optionsListFolder = ref<FormOption[]>([]);
const isSameCurrentFolder = ref<boolean>(false);

const onMoveFolder = async () => {
  if (selectedListFolder.value) {
    selectedMoveFolder.value = props.folderList.find(
      (folder: StorageUploadDirectoryType) =>
        folder.directoryNo.toString() === selectedListFolder.value
    );
    emits('close', selectedMoveFolder.value);
  }
};

const handleChooseFolder = (value: string) => {
  isSameCurrentFolder.value = props.currentFolder?.directoryNo.toString() === value;
};

const init = () => {
  if (props.folderList?.length) {
    optionsListFolder.value = props.folderList.map((folder: StorageUploadDirectoryType) => {
      return {
        value: folder.directoryNo.toString(),
        label: folder.directoryName
      };
    });
  }
};

init();
</script>
