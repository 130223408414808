<!--StorageHeader-->
<template>
  <span class="inline-flex items-center gap-12">
    <st-tooltip-text-over position="bottom-start" hasArrow :content="projectTitle">
      <span
        class="text-md font-bold leading-sm text-on-surface-elevation-1 truncate max-w-[66.2rem]"
      >
        {{ projectTitle }}
      </span>
    </st-tooltip-text-over>
    <span class="-ml-[1rem] text-md">
      {{ $t('studio.prj_prod_mngmt.storage_upload_done.storage_folder') }}
    </span>
    <span v-if="isAttachedFile" class="text-md font-medium leading-sm text-on-surface-elevation-1">
      {{ $t('studio.prj_prod_mngmt.storage_select_attachment.no_folder_file.subtitle') }}
    </span>
    <template v-else>
      <span
        class="inline-flex items-center gap-4 text-md font-regular leading-lg text-on-surface-elevation-3"
      >
        <em
          class="font-[500]"
          :class="[
            storageUsageData.usedInBytes === 0 ? 'text-placeholder' : '',
            LIMIT_EXCEEDED_CLASS[limitExceeded]
          ]"
        >
          {{ formatBytes(storageUsageData.usedInBytes) }}
        </em>
        / {{ formatBytes(storageUsageData.capacityInBytes, 0) }}
        <s-tooltip
          v-if="limitExceeded === 'full'"
          arrow
          :content="contentTooltip"
          :duration="0"
          useFlip
          flipOnUpdate
          placement="bottom"
          trigger="mouseenter focus"
          :theme="'studio-tooltip'"
          :zIndex="2500"
          :allowHTML="true"
          :offset="[0, 8]"
          class="inline-flex h-16"
        >
          <template #target>
            <s-icon
              size="xl"
              icon="ic-v2-state-info-circle-line"
              class="text-on-surface-elevation-3"
            />
          </template>
        </s-tooltip>
      </span>
    </template>
  </span>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import { EIGHTEEN_GIGABYTES, TEN_GIGABYTES } from '@/constants/file.const';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import { formatBytes } from '@/utils/file.util';

defineProps<{
  isAttachedFile: boolean;
}>();

const { t } = useI18n();

const uploadStore = useStorageUploadStore();

const { storageUsageData } = storeToRefs(uploadStore);
const LIMIT_EXCEEDED_CLASS = {
  lower: 'text-on-surface-elevation-2',
  half: 'text-brand-primary',
  full: 'text-[#EC3B47]'
};

const contentTooltip = `<p class='text-center'>${t(
  'studio.prj_prod_mngmt.storage_header_limit_guide'
)}</p>`;

const projectTitle = computed(() => {
  return storageUsageData.value?.projectName;
});

const limitExceeded = computed(() => {
  // currentVolume lower 10GB
  if (storageUsageData.value?.usedInBytes < TEN_GIGABYTES) {
    return 'lower';
  }
  if (
    storageUsageData.value?.usedInBytes >= TEN_GIGABYTES &&
    storageUsageData.value?.usedInBytes < EIGHTEEN_GIGABYTES
  ) {
    return 'half';
  }
  return 'full';
});
</script>
