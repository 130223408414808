<template>
  <s-dialog to="storage-popup" size="md" :open="!!projectId">
    <s-dialog-overlay />
    <s-dialog-panel class="relative !w-[93.2rem] !h-[85rem]">
      <st-dialog-header @clickClose="onClose">
        <storage-header :isAttachedFile="isAttachedFile" />
      </st-dialog-header>
      <s-dialog-content v-end-scroll="loadMore" class="flex-1">
        <s-dialog-content-body class="flex flex-col min-h-full pt-12 relative">
          <!-- 스토리지 검색 -->
          <storage-search />

          <!-- 폴더 선택시 타이틀 -->
          <!-- Folder Branch Name title -->
          <storage-folder-title v-if="selectedFolder" />

          <!-- 폴더 목록 -->
          <!-- Content folder list -->
          <storage-folder-list
            v-if="!isSearchResultEmpty"
            :isAttachedFile="isAttachedFile"
            :isImageListEmpty="!fileList.length"
          />

          <div v-bind="getRootProps()" class="contents">
            <input v-bind="getInputProps()" />
            <!-- 파일 목록 -->
            <!-- Content file list -->
            <storage-file-list
              v-if="!isShowAttachmentGuide && !isShowUploadGuide"
              :isShowDropTarget="isDragActive"
              :isChooseOneFile="props.isChooseOneFile"
            />

            <!-- 스토리지 > 초기 가이드 영역 -->
            <!-- Upload guide in begin -->
            <storage-upload-guide v-if="isShowUploadGuide && !isLoadingFile" />

            <!-- 첨부 파일 선택 > 초기 가이드 영역 -->
            <!-- Attachment/Select guide in begin -->
            <storage-attachment-guide v-if="isShowAttachmentGuide && !isLoadingFile" />

            <!-- 검색 결과 없음 -->
            <!-- Content search empty -->
            <storage-search-empty v-if="isSearchResultEmpty && !isLoadingFile" />
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button v-if="isAttachedFile" :isDisabled="!selectedFiles.length" @click="submitData">
          {{ $t('studio.prj_prod_mngmt.storage_select_attachment.no_folder_file.btn_upload_file') }}
        </s-button>
        <s-button
          v-else
          icon="ic-v2-object-upload-line"
          :isDisabled="isDisabledButtonUpload"
          @click="handleUpload"
        >
          {{ $t('studio.prj_prod_mngmt.storage_upload_method1.btn_upload') }}
        </s-button>
        <span ref="openUpload" class="w-0 h-0" @click="open"></span>
      </s-dialog-footer>

      <!--  스토리지 최초 진입 시 노출 -->
      <storage-first-entry />

      <s-toast-area
        v-bind="$props"
        class="absolute bottom-[6.8rem] w-auto [&>*]:rounded-full [&>*>p]:text-base"
      />
    </s-dialog-panel>
  </s-dialog>
  <s-portal-target name="storage-popup" />
</template>
<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';
import { axios } from 'seed-core';
import { computed, getCurrentInstance, onUnmounted, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { checkFileExistApi } from '@/apis/storage-upload.api';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import StoragePopupInvalidTypeUpload from '@/components/storage/modal/storage-popup-invalid-type-upload.vue';
import StoragePopupLoadingUpload from '@/components/storage/modal/storage-popup-loading-upload.vue';
import StorageAttachmentGuide from '@/components/storage/storage-attachment-guide.vue';
import StorageFileList from '@/components/storage/storage-file-list.vue';
import StorageFirstEntry from '@/components/storage/storage-first-entry.vue';
import StorageFolderList from '@/components/storage/storage-folder-list.vue';
import StorageFolderTitle from '@/components/storage/storage-folder-title.vue';
import StorageHeader from '@/components/storage/storage-header.vue';
import StorageSearch from '@/components/storage/storage-search.vue';
import StorageSearchEmpty from '@/components/storage/storage-search-empty.vue';
import StorageUploadGuide from '@/components/storage/storage-upload-guide.vue';
import { useApp } from '@/composables/useApp';
import {
  closeLatestDialog,
  showAlert,
  showDialog,
  showMaxLengthFileNameDialog
} from '@/composables/useDialog';
import { useDropZone } from '@/composables/useDropZone';
import {
  FILE_INVALID_TYPE,
  FILE_TOO_LARGE,
  FILE_TOO_SMALL,
  TOO_MANY_FILES
} from '@/constants/dropzone.const';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import {
  FILE_STORAGE_MINES_TYPE,
  MAX_LENGTH_FILE_NAME,
  ONE_HUNDRED_MEGABYTES
} from '@/constants/file.const';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { StorageFileUploadOptions, StorageUploadPopupProps } from '@/types/common/popup.type';
import type { FileUploadOptions } from '@/types/dropzone.type';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import { getFileNameWithoutExtension } from '@/utils/file.util';
import { delayTime } from '@/utils/time.util';

useHead({
  bodyAttrs: {
    class: 'overflow-hidden'
  }
});

const props = withDefaults(defineProps<StorageUploadPopupProps>(), {
  isAttachedFile: false,
  isChooseOneFile: false
});

const emit = defineEmits<{
  close: [images?: StorageUploadFileType[] | null];
}>();

const { checkMenuWritable } = useApp();

const { t } = useI18n();
const route = useRoute();
const routeProjectId = route.params.projectId as string;

const storageUploadStore = useStorageUploadStore();
const {
  fileList,
  folderList,
  selectedFolder,
  selectedFiles,
  searchKeyword,
  projectId,
  cancelTokenSource,
  isAttachedFile,
  acceptFileType,
  isLoadingFile,
  storageUsageData,
  canLoadMoreFile,
  uploadUUID
} = storeToRefs(storageUploadStore);

const openUpload = ref<any>();
const isDisabledButtonUpload = ref<boolean>(false);
const isShowUploadGuide = computed(
  () =>
    !storageUsageData.value.usedInBytes &&
    !folderList.value.length &&
    !props.isAttachedFile &&
    !searchKeyword.value
);
const isShowAttachmentGuide = computed(
  () =>
    !storageUsageData.value.usedInBytes &&
    !fileList.value.length &&
    !selectedFolder.value &&
    props.isAttachedFile &&
    !searchKeyword.value
);
const isSearchResultEmpty = computed(
  () =>
    !fileList.value.length &&
    searchKeyword.value &&
    (!folderList.value.length || selectedFolder.value)
);
const isOffline = ref<boolean>(false);

const onClose = (images?: StorageUploadFileType[]) => {
  emit('close', images?.length ? images : null);
};

const proxy: any = getCurrentInstance()?.proxy;
const showToastSuccessUploaded = (count: number) => {
  proxy?.$SToast({
    text: t('studio.prj_prod_mngmt.storage_upload_method1.upload_done_msg', { fileNum: count }),
    duration: 1200
  });
};

// Configurations for upload files

const uploadOptions = computed(() => {
  const uploadOptions = props.uploadOptions;
  const defaultConfigs: StorageFileUploadOptions = {
    noClick: true,
    maxSize: ONE_HUNDRED_MEGABYTES,
    minSize: 1,
    maxFiles: 10,
    multiple: true,
    preventDropOnDocument: true,
    accept: uploadOptions?.accept || FILE_STORAGE_MINES_TYPE
  };
  if (props.isAttachedFile) {
    defaultConfigs.noDrag = true;
    defaultConfigs.noKeyboard = true;
  }
  // if (storageUsageData.value.usedInBytes >= storageUsageData.value.capacityInBytes) {
  //   defaultConfigs.noDrag = true;
  // }
  const config: Partial<FileUploadOptions> = {
    ...defaultConfigs,
    ...uploadOptions,
    onDrop
  };
  return config;
});

const saveFiles = async (files: any[]) => {
  if (!(await checkMenuWritable())) {
    return;
  }
  let isCancelUpload;
  showDialog({
    component: shallowRef(StoragePopupLoadingUpload),
    props: {}
  }).then(async (isCancel: unknown) => {
    if (isCancel) {
      const isCancel = await showAlert({
        content: t('studio.prj_prod_mngmt.storage_upload_method1.upload_cancel_msg')
      });
      if (isCancel) {
        isCancelUpload = true;
        cancelTokenSource.value.cancel('Cancel upload files');
      }
    }
  });

  const { newFileCount, failValues, successValues } = await storageUploadStore.uploadFiles(files);
  if (!isCancelUpload) {
    closeLatestDialog();
  }
  if (successValues.length && !failValues.length) {
    await delayTime(100);
    await storageUploadStore.confirmAllFilesOnSession(projectId.value, uploadUUID.value);
    await storageUploadStore.fetchDataReset();
    showToastSuccessUploaded(newFileCount);
    uploadUUID.value = '';
  } else if (window.navigator.onLine) {
    await storageUploadStore.fetchStorageUsage(false);
    uploadUUID.value = '';
  }
  if (failValues?.length) {
    const statusCode = failValues[0]?.statusCode;
    const statusMessage = failValues[0].message as string;
    if (statusCode === STATUS_CODE.FILE_SIZE_ZERO) {
      await showAlert({
        content: t('studio.prj_prod_mngmt.storage_upload_method1.upload_damaged_msg')
      });
    } else if (statusCode === STATUS_CODE.PROJECT_CAPACITY_EXCEEDED) {
      await showAlert({
        content: t('studio.prj_prod_mngmt.storage_upload_method1.storage_exceed_msg')
      });
    } else if (
      (statusCode === STATUS_CODE.INTERNAL_SERVER_ERROR &&
        statusMessage.includes('Network Error')) ||
      !window.navigator.onLine ||
      isOffline.value
    ) {
      await showAlert({
        content: t('studio.prj_prod_mngmt.storage_upload_method1.upload_failed_msg')
      });
    } else if (statusCode === STATUS_CODE.UNSUPPORTED_FILE_TYPE) {
      await showDialog({
        component: shallowRef(StoragePopupInvalidTypeUpload),
        props: {}
      });
    } else if (!isCancelUpload) {
      await showAlert({
        severity: 'info',
        content: t(COMMON_ERROR_MESSAGE_KEY)
      });
    }
  }
  isDisabledButtonUpload.value = false;
  cancelTokenSource.value = axios.CancelToken.source();
};

const onDrop = (acceptFiles: any, _rejectReasons: any) => {
  if (storageUsageData.value.usedInBytes >= storageUsageData.value.capacityInBytes) {
    showAlert({
      content: t('studio.prj_prod_mngmt.storage_upload_method1.storage_exceed_msg')
    });
    return;
  }
  isDisabledButtonUpload.value = true;
  if (listErrors.value.length > 0) {
    const code = listErrors.value[0].code;
    switch (code) {
      case FILE_INVALID_TYPE:
        showDialog({
          component: shallowRef(StoragePopupInvalidTypeUpload),
          props: {}
        });
        break;
      case FILE_TOO_SMALL:
        showAlert({
          content: t('studio.prj_prod_mngmt.storage_upload_method1.upload_damaged_msg')
        });
        break;
      case TOO_MANY_FILES:
        showAlert({
          content: t('studio.prj_prod_mngmt.storage_upload_method1.file_num_over10_msg')
        });
        break;
      case FILE_TOO_LARGE:
        showAlert({
          content: t('studio.prj_prod_mngmt.storage_upload_method1.upload_limit_msg')
        });
        break;
      default:
        break;
    }
    isDisabledButtonUpload.value = false;
  } else {
    let isMaxLengthFileName = false;
    acceptFiles.forEach((file: any) => {
      if (getFileNameWithoutExtension(file.name).length >= MAX_LENGTH_FILE_NAME) {
        isMaxLengthFileName = true;
      }
    });
    if (isMaxLengthFileName) {
      isDisabledButtonUpload.value = false;
      showMaxLengthFileNameDialog();
      return;
    }
    saveFiles(acceptFiles);
  }
};

const handleUpload = async () => {
  if (!(await checkMenuWritable())) {
    return;
  }
  openUpload.value.click();
};

const { getRootProps, getInputProps, isDragActive, open, listErrors } = useDropZone(
  uploadOptions.value
);

// Methods for storage file list

const submitData = async () => {
  try {
    const ids = selectedFiles.value.map((file: StorageUploadFileType) => file.fileId).join(',');
    const result = await checkFileExistApi(projectId.value, ids);

    if (result?.status) {
      onClose(selectedFiles.value);
    }
  } catch (err) {
    const error = err as ErrorResponse;

    if (error.statusCode === STATUS_CODE.FILE_NOT_FOUND) {
      const result = await showAlert({
        content: t(COMMON_ERROR_MESSAGE_KEY)
      });

      if (result) {
        selectedFiles.value = [];
        storageUploadStore.setPageFileParams(1);
        await storageUploadStore.fetchFiles();
      }
    }
  }
};

const resetSelectedFiles = () => {
  selectedFiles.value = [];
  fileList.value = [];
};

// Handle data

const getDataInit = async () => {
  await Promise.all([storageUploadStore.fetchFiles(), storageUploadStore.fetchFolder()]);
};

const funcOffline = () => {
  isOffline.value = true;
};

const funcOnline = () => {
  isOffline.value = false;
};

const init = async () => {
  addEventListener('offline', funcOffline);
  addEventListener('online', funcOnline);
  const acceptedFilesArray = Array.isArray(props.uploadOptions?.accept)
    ? props.uploadOptions?.accept
    : props.uploadOptions?.accept?.split(',');
  isAttachedFile.value = props.isAttachedFile;
  acceptFileType.value = acceptedFilesArray || acceptFileType.value;
  if (props.projectId || routeProjectId) {
    projectId.value = props.projectId || routeProjectId;
    await getDataInit();
  }
};

const loadMore = async () => {
  if (canLoadMoreFile.value) {
    storageUploadStore.loadMorePageFileParams();
    await storageUploadStore.fetchFiles();
  }
};

await init();

watch(
  () => selectedFolder.value,
  async () => {
    resetSelectedFiles();
    storageUploadStore.setDirectoryNoFileParams(selectedFolder?.value?.directoryNo);
    await storageUploadStore.fetchFiles();
  }
);

watch(
  () => isOffline.value,
  async (newStatus: boolean, oldStatus: boolean) => {
    if (!oldStatus && newStatus) {
      cancelTokenSource.value.cancel('Cancel upload files');
    }
  }
);

onUnmounted(() => {
  storageUploadStore.resetData();
  removeEventListener('offline', funcOffline);
  removeEventListener('online', funcOnline);
});
</script>
