<!--StoragePopupRegisterFolder-->
<template>
  <div>
    <s-dialog to="storage-popup-register-folder" size="sm" :open="true">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body>
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
              <safe-html
                :html="
                  props.isNew
                    ? $t('studio.prj_prod_mngmt.storage_no_folder_file.create_folder_title')
                    : $t('studio.prj_prod_mngmt.storage_only_folder.edit_folder_name_title')
                "
              />
            </p>
            <div class="mt-8">
              <input-text
                size="lg"
                variant="outline"
                :placeholder="
                  $t('studio.prj_prod_mngmt.storage_no_folder_file.create_folder_place_holder')
                "
                name="storageFolderName"
                :allowInputMaxLength="false"
                :modelValue="inputValue"
                :rules="{
                  required: $t(
                    'studio.prj_prod_mngmt.storage_no_folder_file.create_folder_val_msg1'
                  ),
                  max_length: {
                    value: MAX_LENGTH_INPUT,
                    message: $t('studio.common.def_key.exceed', { length: MAX_LENGTH_INPUT })
                  }
                }"
                @change="() => (isChangeInput = true)"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button variant="outline" class="w-full" @click="onCancel">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button class="w-full" @click="submitForm">
            <safe-html
              :html="
                props.isNew
                  ? $t('studio.prj_prod_mngmt.storage_no_folder_file.create_folder_cf_btn')
                  : $t('studio.prj_prod_mngmt.storage_only_folder.btn_edit_folder_name_cf')
              "
            />
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>
    <s-portal-target name="storage-popup-register-folder" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldValue } from 'vee-validate';
import { onMounted, ref } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';
import { REGISTER_EDIT_SUBMIT_MODE } from '@/constants/storage-upload.const';
import { RuleNames } from '@/enums/validation.enum';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import type {
  StoragePopupRegisterFolderEmits,
  StorageUploadDirectoryType
} from '@/types/storage-upload/storage-upload-file.type';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  currentFolder?: StorageUploadDirectoryType;
  isNew: boolean;
}>();

const emits = defineEmits<{
  close: [data: StoragePopupRegisterFolderEmits | null | undefined];
}>();

const storageUploadStore = useStorageUploadStore();
const { folderList } = storeToRefs(storageUploadStore);

const { handleSubmit, setErrors } = useForm({
  initialValues: {
    storageFolderName: ''
  }
});

const storageFolderNameValue = useFieldValue<string>('storageFolderName');
const setStorageFolderNameValue = useSetFieldValue<string>('storageFolderName');
const inputValue = ref<string>('');
const isChangeInput = ref<boolean>(false);

const MAX_LENGTH_INPUT = 60;

const addNewFolder = async () => {
  const params: StoragePopupRegisterFolderEmits = {
    type: REGISTER_EDIT_SUBMIT_MODE.CREATE,
    folderName: storageFolderNameValue.value
  };
  onCancel(params);
};

const editFolder = async () => {
  const params: StoragePopupRegisterFolderEmits = {
    type: REGISTER_EDIT_SUBMIT_MODE.EDIT,
    folderName: storageFolderNameValue.value
  };
  onCancel(params);
};

const onCancel = (data?: StoragePopupRegisterFolderEmits | null | undefined) => {
  emits('close', data);
};

const submitForm = handleSubmit(() => {
  let isSameName = false;
  const isExists = folderList.value.some((item: StorageUploadDirectoryType) => {
    if (
      item.directoryName === storageFolderNameValue.value.trim() &&
      !props.isNew &&
      !isChangeInput.value
    ) {
      isSameName = true;
    }
    return item.directoryName === storageFolderNameValue.value;
  });
  if (isSameName) {
    onCancel();
    return;
  }
  if (isExists) {
    const errMsg = generateErrorMsg(
      'studio.prj_prod_mngmt.storage_no_folder_file.create_folder_val_msg2',
      RuleNames.CHECK_DUPLICATE
    );
    setErrors({ storageFolderName: errMsg });
    return;
  }
  if (props.isNew) {
    addNewFolder();
  } else {
    editFolder();
  }
});

onMounted(() => {
  if (props.currentFolder) {
    setStorageFolderNameValue(props.currentFolder.directoryName);
  }
});
</script>
