<!--StorageSearchEmpty-->
<template>
  <div class="flex-1 flex flex-col justify-center items-center gap-8 min-h-220">
    <img
      src="@/assets/images/common/not-found-storage-upload.svg"
      alt="storage search empty icon"
      class="w-180 h-180 object-contain"
    />
    <p class="text-lg font-bold leading-lg text-on-surface-elevation-2 text-center">
      <safe-html
        :html="
          $t('studio.prj_prod_mngmt.storage_srch_in_folder.no_result_msg1', {
            searchValue: searchKeyword
          })
        "
      />
    </p>
    <p class="text-md font-medium leading-lg text-on-surface-elevation-3 text-center">
      {{ $t('studio.prj_prod_mngmt.storage_srch_in_folder.no_result_msg2') }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import SafeHtml from '@/components/common/safe-html.vue';
import { useStorageUploadStore } from '@/stores/storage-upload.store';

const storageUploadStore = useStorageUploadStore();
const { searchKeyword } = storeToRefs(storageUploadStore);
</script>
