<!--StorageAttachmentGuide-->
<template>
  <div
    class="flex-1 flex flex-col justify-center items-center gap-20 min-h-220 border-solid border-1 border-border bg-[var(--stds-glob-color-gray20)] rounded-3xl"
  >
    <safe-html
      class="text-md leading-lg text-on-surface-elevation-3 text-center"
      tag="p"
      :html="$t('studio.prj_prod_mngmt.storage_select_attachment.no_folder_file.guide')"
    />
    <s-button size="md" variant="secondary" @click="goToProductManagement">
      {{ $t('studio.prj_prod_mngmt.storage_select_attachment.no_folder_file.btn_go_to_prj_mngmt') }}
    </s-button>
  </div>
</template>
<script setup lang="ts">
import SafeHtml from '@/components/common/safe-html.vue';
import { PROJECT_MNG_PAGE_URL } from '@/constants/url.const';
import { redirectTo } from '@/utils/common.util';

const goToProductManagement = () => {
  redirectTo(PROJECT_MNG_PAGE_URL, {
    open: {
      target: '_blank'
    }
  });
};
</script>
