<!--StorageFirstEntry-->
<template>
  <div v-if="isShow" class="absolute inset-0 flex justify-center items-center">
    <s-dialog-overlay class="absolute inset-0" />
    <s-dialog-panel class="relative w-[54rem]">
      <s-dialog-content class="pt-[3.8rem] pb-[3.4rem]">
        <div class="flex flex-col justify-center items-center gap-16">
          <img
            src="@/assets/images/storage/storage-first-entry-image.svg"
            alt=""
            class="w-260 h-180 object-contain"
          />
          <p class="text-lg leading-lg text-on-surface-elevation-2 text-center">
            <safe-html :html="$t('studio.prj_prod_mngmt.storage_intro_guide')" />
          </p>
        </div>
        <button type="button">
          <s-icon
            size="4xl"
            icon="ic-v2-control-close-line"
            srOnlyText="Close"
            class="absolute top-20 right-32 text-on-surface-elevation-2"
            @click="onClose"
          />
        </button>
      </s-dialog-content>
    </s-dialog-panel>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';
import { useStorageUploadStore } from '@/stores/storage-upload.store';

const storageUploadStore = useStorageUploadStore();
const { isAttachedFile } = storeToRefs(storageUploadStore);

const isShow = ref<boolean>(false);

const onClose = () => {
  isShow.value = false;
};

const checkIsFirstTimeUpload = () => {
  const isSecondTimeUpload = localStorage.getItem('is-second-time-upload');
  if (!isSecondTimeUpload && !isAttachedFile.value) {
    isShow.value = true;
    localStorage.setItem('is-second-time-upload', 'true');
  }
};

checkIsFirstTimeUpload();
</script>
