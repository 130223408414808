import { SORT_DIRECTION } from '@/constants/common.const';

export const STORAGE_UPLOAD_SORT_OPTIONS = {
  CREATED_AT: 'CREATED_AT',
  MODIFIED_AT: 'MODIFIED_AT',
  USER_FILE_NAME: 'USER_FILE_NAME',
  DIRECTORY_NAME: 'DIRECTORY_NAME'
};

export const STORAGE_UPLOAD_FILTER_VALUE = {
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST',
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
};

export const STORAGE_UPLOAD_FILE_DEFAULT_SIZE = 20;

export const STORAGE_UPLOAD_FILE_DEFAULT_PAGE = 1;

export const STORAGE_UPLOAD_FOLDER_DEFAULT_SIZE = 9999;

export const STORAGE_UPLOAD_FOLDER_DEFAULT_PAGE = 1;

export const INITIAL_STORAGE_UPLOAD_FILE_PARAMS = {
  page: STORAGE_UPLOAD_FILE_DEFAULT_PAGE,
  size: STORAGE_UPLOAD_FILE_DEFAULT_SIZE,
  sort: STORAGE_UPLOAD_SORT_OPTIONS.CREATED_AT,
  direction: SORT_DIRECTION.DESC,
  directoryNo: undefined,
  q: undefined
};

export const INITIAL_STORAGE_UPLOAD_FOLDER_PARAMS = {
  size: STORAGE_UPLOAD_FOLDER_DEFAULT_SIZE,
  page: STORAGE_UPLOAD_FOLDER_DEFAULT_PAGE,
  sort: STORAGE_UPLOAD_SORT_OPTIONS.CREATED_AT,
  direction: SORT_DIRECTION.DESC,
  q: undefined
};

export const REGISTER_EDIT_SUBMIT_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
} as const;
