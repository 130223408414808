<template>
  <s-dialog to="storage-popup-loading-upload" size="sm" open>
    <s-dialog-overlay />
    <s-dialog-panel>
      <s-dialog-content>
        <s-dialog-content-body>
          <div class="flex flex-col items-center justify-center min-h-72">
            <p class="text-lg leading-lg text-on-surface-elevation-2 text-center">
              <safe-html :html="$t('studio.prj_prod_mngmt.storage_upload_method1.uploading_msg')" />
            </p>
            <s-circular-progress size="sm" class="mt-4" />
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button variant="tertiary" class="w-full" @click="onCancel">
          {{ $t('studio.common.popup_case_cancel_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="storage-popup-loading-upload" />
</template>

<script setup lang="ts">
import SafeHtml from '@/components/common/safe-html.vue';

const emits = defineEmits<{
  close: [isData: boolean];
}>();

const onCancel = () => {
  emits('close', true);
};
</script>
