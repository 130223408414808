<!-- StorageFolderTitle -->
<template>
  <div class="flex items-center gap-8 min-h-36 mb-24">
    <button type="button" class="shrink-0 inline-flex p-[.6rem]" @click="backToMain">
      <s-icon size="4xl" icon="ic-v2-control-arrow-left-line" />
    </button>
    <p class="mr-4 text-xl font-bold leading-lg text-on-surface-elevation-1 truncate tracking-base">
      <s-tooltip
        arrow
        :content="selectedFolder?.directoryName"
        :distance="7"
        placement="bottom-start"
        trigger="mouseenter"
        flipOnUpdate
        useFlip
        :theme="'studio-tooltip'"
        :zIndex="2500"
        :allowHTML="true"
      >
        <template #target> {{ selectedFolder?.directoryName }}</template>
      </s-tooltip>
    </p>
    <s-menu-popup
      v-if="!isAttachedFile"
      :distance="0"
      :offset="[0, 0]"
      :flipOnUpdate="true"
      :maxWidth="200"
      placement="bottom-end"
      trigger="click"
      class="shrink-0 inline-flex ml-auto p-8"
    >
      <template #target>
        <s-icon
          size="3xl"
          icon="ic-v2-navigation-option-vertical-fill"
          class="text-on-surface-elevation-2"
        />
      </template>
      <template #menuItems>
        <p
          class="mb-8 px-12 pt-12 pb-16 border-solid border-b-1 border-border text-md font-medium leading-sm text-on-surface-elevation-2"
        >
          {{ selectedFolder?.directoryName }}
        </p>
        <s-menu-popup-item
          icon="ic-v2-community-pencil-line"
          class="min-w-[19.8rem]"
          value="edit"
          @click="onEdit(selectedFolder as StorageUploadDirectoryType)"
        >
          {{ $t('studio.prj_prod_mngmt.storage_only_folder.btn_edit_folder_name') }}
        </s-menu-popup-item>
        <s-menu-popup-item
          icon="ic-v2-object-delete-line"
          class="min-w-[19.8rem]"
          value="delete"
          @click="onDelete(selectedFolder as StorageUploadDirectoryType)"
        >
          {{ $t('studio.prj_prod_mngmt.storage_only_folder.btn_del_folder') }}
        </s-menu-popup-item>
      </template>
    </s-menu-popup>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useApp } from '@/composables/useApp';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import type { StorageUploadDirectoryType } from '@/types/storage-upload/storage-upload-file.type';

const app = useApp();
const storageUploadStore = useStorageUploadStore();
const { selectedFolder, isAttachedFile } = storeToRefs(storageUploadStore);

const onEdit = async (folder?: StorageUploadDirectoryType) => {
  await storageUploadStore.createOrEditFolderItem(folder);
};

const onDelete = async (folderItem?: StorageUploadDirectoryType) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  await storageUploadStore.deleteFolderItem(folderItem, backToMain);
};

const backToMain = () => {
  selectedFolder.value = null;
};
</script>
