<!--StorageFileList-->
<template>
  <div
    v-if="!fileList.length && selectedFolder && !isLoadingFile && !searchKeyword"
    class="relative flex-1 flex flex-col justify-center items-center h-full pb-[5.2rem]"
  >
    <p class="text-lg font-bold leading-lg text-on-surface-elevation-2 text-center">
      {{ $t('studio.prj_prod_mngmt.storage_folder_selected.no_file_msg') }}
    </p>

    <storage-drop-target v-if="isShowDropTarget" />
  </div>
  <template v-else>
    <div
      v-if="fileList.length || (folderList.length && !selectedFolder)"
      class="flex items-center gap-8 min-h-36 mb-16 px-4"
      :class="{ invisible: !fileList.length }"
    >
      <template v-if="!isLoadingFile">
        <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
          {{ $t('studio.prj_prod_mngmt.storage_upload_done.file_title') }}
        </p>
        <checkbox
          v-if="fileList?.length > 0"
          v-model="isToggleCheckAll"
          :disabled="isChooseOneFile"
          :options="{
            class: 'h-20',
            align: 'middle',
            size: 'md'
          }"
          @update:modelValue="handleCheckAll()"
        />
        <em class="text-2xl font-medium leading-xl text-brand-primary -ml-8">
          {{ totalElementsFileList }}
        </em>
      </template>
      <template v-else>
        <s-skeleton class="w-[9rem] h-[2.2rem] bg-inverse-elevation-5 rounded-sm" />
      </template>
      <div
        class="shrink-0 ml-auto flex gap-4"
        :class="
          (selectedFiles.length > 0 || (isToggleCheckAll && fileList.length > 0)) && !isAttachedFile ? '' : 'invisible'
        "
      >
        <button
          type="button"
          class="p-8 text-on-surface-elevation-2"
          @click="onMoveFile(selectedFiles)"
        >
          <s-icon size="3xl" icon="ic-v2-content-folder-move-line" />
        </button>
        <button
          type="button"
          class="p-8 text-on-surface-elevation-2"
          @click="onDeleteFile(selectedFiles)"
        >
          <s-icon size="3xl" icon="ic-v2-object-delete-line" />
        </button>
      </div>
    </div>

    <div
      v-if="fileList.length || (folderList.length && !selectedFolder)"
      :class="{ 'opacity-0': !fileList.length }"
      class="relative flex-1"
    >
      <!-- Card 타입 -->
      <div
        v-if="isFileViewModeCard"
        :class="fileList?.length ? '' : 'opacity-0'"
        class="grid grid-cols-5 grid-flow-row gap-[1rem]"
      >
        <storage-file-card-item
          v-for="(image, idx) in fileList"
          :key="image.fileId"
          :file="image"
          :selectValue="listTriggerCheckBox[idx]"
          :hiddenDropdown="isAttachedFile"
          :disabled="isDisabledItem(image.fileType)"
          class="col-span-1"
          @onChangeCheckbox="chooseImage(image, idx)"
          @onMoveFile="handleMoveFile($event)"
          @onDeleteFile="handleDeleteFile($event)"
          @onDownloadFile="handleDownloadFile($event)"
        />
        <template v-if="isLoadingFile">
          <storage-file-card-item-skeleton v-for="i in 10" :key="i" class="col-span-1" />
        </template>
      </div>
      <!-- List 타입 -->
      <div
        v-if="!isFileViewModeCard"
        :class="fileList?.length ? '' : 'opacity-0'"
        class="relative flex-1"
      >
        <storage-file-list-item
          v-for="(image, idx) in fileList"
          :key="image.fileId"
          :file="image"
          :selectValue="listTriggerCheckBox[idx]"
          :hiddenDropdown="isAttachedFile"
          :disabled="isDisabledItem(image.fileType)"
          @onChangeCheckbox="chooseImage(image, idx)"
          @onMoveFile="handleMoveFile($event)"
          @onDeleteFile="handleDeleteFile($event)"
          @onDownloadFile="handleDownloadFile($event)"
        />
        <template v-if="isLoadingFile">
          <storage-file-list-item-skeleton v-for="i in 10" :key="i" />
        </template>
      </div>
      <!--      <storage-drop-target v-if="isShowDropTarget" />-->
    </div>
  </template>

  <div v-if="!fileList.length && isLoadingFile" class="relative flex-1">
    <div v-if="isFileViewModeCard" class="grid grid-cols-5 grid-flow-row gap-[1rem]">
      <storage-file-card-item-skeleton v-for="i in 20" :key="i" class="col-span-1" />
    </div>
    <div v-if="!isFileViewModeCard" class="relative flex-1">
      <storage-file-list-item-skeleton v-for="i in 20" :key="i" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { cloneDeep } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

import StorageDropTarget from '@/components/storage/storage-drop-target.vue';
import StorageFileCardItem from '@/components/storage/storage-file-card-item.vue';
import StorageFileCardItemSkeleton from '@/components/storage/storage-file-card-item-skeleton.vue';
import StorageFileListItem from '@/components/storage/storage-file-list-item.vue';
import StorageFileListItemSkeleton from '@/components/storage/storage-file-list-item-skeleton.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { useApp } from '@/composables/useApp';
import { ARRAY_FILE_TYPE } from '@/constants/file.const';
import { useStorageUploadStore } from '@/stores/storage-upload.store';
import type { StorageUploadFileType } from '@/types/storage-upload/storage-upload-file.type';
import { downLoadFile } from '@/utils/file.util';

const props = defineProps<{
  isShowDropTarget: boolean;
  isChooseOneFile: boolean;
}>();

const app = useApp();

const storageUploadStore = useStorageUploadStore();
const {
  fileList,
  folderList,
  selectedFiles,
  selectedFolder,
  isFileViewModeCard,
  isLoadingFile,
  isAttachedFile,
  searchKeyword,
  totalElementsFileList,
  acceptFileType,
  filterParam
} = storeToRefs(storageUploadStore);
const isToggleCheckAll = ref<boolean>(false);
const listTriggerCheckBox = ref<boolean[]>([]);

const onMoveFile = async (files: StorageUploadFileType[]) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  const result = await storageUploadStore.moveFiles(files);
  if (result) {
    listTriggerCheckBox.value = listTriggerCheckBox.value.map(() => false);
    isToggleCheckAll.value = false;
    storageUploadStore.setPageFileParams(1);
    await storageUploadStore.fetchFiles();
  }
};

const onDeleteFile = async (files: StorageUploadFileType[]) => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  await storageUploadStore.removeFile(files);
};

const chooseImage = (file: StorageUploadFileType, idx: number) => {
  const currentIdx: number = selectedFiles.value.findIndex(
    (item: StorageUploadFileType) => item?.fileId === file?.fileId
  );

  // Just select one file in case of isChooseOneFile
  if (props.isChooseOneFile) {
    if (currentIdx === -1) {
      selectedFiles.value = [file];
    } else {
      selectedFiles.value = [];
    }
    listTriggerCheckBox.value = listTriggerCheckBox.value.map(() => false);
    listTriggerCheckBox.value[idx] = true;
    return;
  }

  // Can select multiple files
  if (currentIdx === -1) {
    selectedFiles.value.push(file);
    listTriggerCheckBox.value[idx] = true;
  } else {
    selectedFiles.value.splice(currentIdx, 1);
    listTriggerCheckBox.value[idx] = false;
  }
};

const handleMoveFile = (file: StorageUploadFileType) => {
  onMoveFile([file]);
};

const handleDownloadFile = (file: StorageUploadFileType) => {
  downLoadFile(file.linkCdn, file.fullFileName);
};

const handleDeleteFile = (file: StorageUploadFileType) => {
  onDeleteFile([file]);
};

const isDisabledItem = (type: string): boolean => {
  return (
    isAttachedFile.value &&
    !acceptFileType.value.find(
      (item: string) => ARRAY_FILE_TYPE[item.toLowerCase()] === type.toLowerCase()
    )
  );
};

const handleCheckAll = () => {
  if (isToggleCheckAll.value) {
    selectedFiles.value = cloneDeep(fileList.value);
    listTriggerCheckBox.value = fileList.value.map((f: StorageUploadFileType) => {
      if (!isAttachedFile.value) {
        return true;
      }
      return !!acceptFileType.value.find(
        (item: string) => ARRAY_FILE_TYPE[item.toLowerCase()] === f.fileType.toLowerCase()
      );
    });
  } else {
    selectedFiles.value = [];
    listTriggerCheckBox.value = fileList.value.map(() => false);
  }
};

const resetSelectedFiles = () => {
  isToggleCheckAll.value = false;
  selectedFiles.value = [];
  listTriggerCheckBox.value = [];
};

watch(
  () => selectedFiles.value,
  () => {
    if (selectedFiles.value.length < fileList.value.length && isToggleCheckAll.value) {
      isToggleCheckAll.value = false;
    }

    if (
      selectedFiles.value.length === fileList.value.length &&
      !isToggleCheckAll.value &&
      selectedFiles.value.length > 0
    ) {
      isToggleCheckAll.value = true;
    }

    if (selectedFiles.value.length === 0) {
      isToggleCheckAll.value = false;
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => selectedFolder.value,
  () => {
    isToggleCheckAll.value = false;
    selectedFiles.value = [];
    listTriggerCheckBox.value = [];
  }
);

watch(
  () => fileList.value,
  () => {
    if (fileList.value.length > selectedFiles.value.length) {
      isToggleCheckAll.value = false;
    }
  }
);

watch(
  () => searchKeyword.value,
  () => {
    resetSelectedFiles();
  }
);

watch(
  () => filterParam.value,
  () => {
    resetSelectedFiles();
  }
);
</script>
