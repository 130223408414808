<!--StorageDropTarget-->
<template>
  <div class="absolute inset-0 bg-surface-elevation-1">
    <div
      class="flex flex-col justify-center items-center gap-8 h-full min-h-220 border-solid border-1 border-border bg-[#F2F6FF] rounded-3xl"
    >
      <p class="text-md leading-lg text-on-surface-elevation-3 text-center">
        {{ $t('studio.prj_prod_mngmt.storage_upload.file_drop_guide') }}
      </p>
    </div>
  </div>
</template>
