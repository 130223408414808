<template>
  <s-dialog to="storage-popup-invalid-type-upload" size="sm" open>
    <s-dialog-overlay />
    <s-dialog-panel>
      <s-dialog-content>
        <s-dialog-content-body>
          <div class="flex flex-col items-center justify-center min-h-72">
            <p class="text-lg leading-lg text-on-surface-elevation-2 text-center">
              <safe-html
                :html="$t('studio.prj_prod_mngmt.storage_upload_method1.wrong_format_msg1')"
              />
            </p>
            <p class="mt-24 text-sm leading-md text-on-surface-elevation-4 text-center">
              {{ $t('studio.prj_prod_mngmt.storage_upload_method1.wrong_format_msg2') }}
            </p>
            <p class="text-lg font-medium leading-lg text-on-surface-elevation-2 text-center">
              {{ $t('studio.prj_prod_mngmt.storage_upload_method1.wrong_format_msg3') }}
            </p>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer>
        <s-button class="w-full" @click="onCancel">
          {{ $t('studio.common.popup_case_cf_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="storage-popup-invalid-type-upload" />
</template>
<script setup lang="ts">
import SafeHtml from '@/components/common/safe-html.vue';

const emits = defineEmits<{
  close: [];
}>();

const onCancel = () => {
  emits('close');
};
</script>
