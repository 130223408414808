<!-- StorageFolderItem.vue -->
<template>
  <div
    class="group flex items-center gap-4 w-full p-8 bg-[var(--stds-glob-color-gray40)] rounded-xl"
  >
    <img src="@/assets/images/storage/icon-folder.svg" alt="" class="shrink-0 w-24 h-24" />
    <p class="flex-1 text-sm font-medium leading-md text-on-surface-elevation-1">
      <st-tooltip-text-over position="bottom-start" hasArrow :content="folderName">
        <span class="line-clamp-1">{{ folderName }}</span>
      </st-tooltip-text-over>
    </p>
    <s-menu-popup
      v-if="!hiddenDropdown"
      :distance="0"
      :offset="[0, 0]"
      :flipOnUpdate="true"
      :maxWidth="200"
      placement="bottom-end"
      trigger="click"
      class="shrink-0 hidden group-hover:inline-flex p-4"
      :class="toggleFolderMenu ? '!inline-flex' : ''"
      @shown="toggleFolderMenu = true"
      @hidden="toggleFolderMenu = false"
      @click="($event: any) => $event.stopPropagation()"
    >
      <template #target>
        <s-icon
          size="xl"
          icon="ic-v2-navigation-option-vertical-fill"
          class="text-on-surface-elevation-4"
        />
      </template>
      <template #menuItems>
        <div @click="($event) => $event.stopPropagation()">
          <p
            class="mb-8 px-12 pt-12 pb-16 border-solid border-b-1 border-border text-md font-medium leading-sm text-on-surface-elevation-2"
          >
            {{ folderName }}
          </p>
          <s-menu-popup-item
            icon="ic-v2-community-pencil-line"
            class="min-w-[19.8rem]"
            value="edit"
            @click="onEdit"
          >
            {{ $t('studio.prj_prod_mngmt.storage_only_folder.btn_edit_folder_name') }}
          </s-menu-popup-item>
          <s-menu-popup-item
            icon="ic-v2-object-delete-line"
            class="min-w-[19.8rem]"
            value="delete"
            @click="onDelete"
          >
            {{ $t('studio.prj_prod_mngmt.storage_only_folder.btn_del_folder') }}
          </s-menu-popup-item>
        </div>
      </template>
    </s-menu-popup>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';

defineProps<{
  folderName: string;
  hiddenDropdown?: boolean;
}>();

const emits = defineEmits<{
  onEdit: [isEdit: boolean];
  onDelete: [isDelete: boolean];
}>();

const toggleFolderMenu = ref(false);

const onEdit = () => {
  emits('onEdit', true);
};

const onDelete = () => {
  emits('onDelete', true);
};
</script>
