<!--StorageSearch-->
<template>
  <div
    v-if="
      (fileList.length ||
        (!fileList.length && folderList.length) ||
        searchKeyword ||
        selectedFolder) &&
        !isLoadingFile
    "
    class="flex items-center gap-24 mb-24"
  >
    <input-text
      v-model="searchInput"
      inputClass="flex-1"
      size="lg"
      variant="outline"
      :placeholder="
        $t('studio.prj_prod_mngmt.storage_srch_result.srchbar_place_holder', {
          folderName:
            selectedFolder?.directoryName ||
            $t('studio.prj_prod_mngmt.storage_upload_done.storage_folder')
        })
      "
      searchable
      @search="onSearch"
      @clear="onSearch"
    />
    <div class="shrink-0 flex items-center">
      <dropdown
        v-model="valueFilterDataStorage"
        :options="filterDataStorageOptions"
        :dropdownBtnClass="'justify-end'"
        :dropdownProps="{
          size: 'sm',
          variant: 'text',
          distance: 0,
          offset: [0, 0],
          placement: 'bottom-end',
          class: 'min-w-[10.5rem]',
          itemDropdownClass: 'min-w-180'
        }"
        @update:modelValue="handleFilterDataChange()"
      />
      <button
        type="button"
        class="ml-16 p-8"
        :class="isFileViewModeCard ? 'text-on-surface-elevation-2' : 'text-placeholder'"
        @click="isChooseViewModeCard()"
      >
        <s-icon
          size="3xl"
          :icon="
            isFileViewModeCard ? 'ic-v2-community-view-card-fill' : 'ic-v2-community-view-card-line'
          "
        />
      </button>
      <button
        type="button"
        class="ml-4 p-8"
        :class="!isFileViewModeCard ? 'text-on-surface-elevation-2' : 'text-placeholder'"
        @click="isChooseViewModeList()"
      >
        <s-icon size="3xl" icon="ic-v2-community-view-list-fill" />
      </button>
    </div>
  </div>
  <div v-if="isLoadingFile" class="flex items-center gap-24 mb-24">
    <s-skeleton class="w-full h-[4.4rem] bg-inverse-elevation-5 rounded-xl" />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { SORT_DIRECTION } from '@/constants/common.const';
import {
  STORAGE_UPLOAD_FILTER_VALUE,
  STORAGE_UPLOAD_SORT_OPTIONS
} from '@/constants/storage-upload.const';
import { useStorageUploadStore } from '@/stores/storage-upload.store';

const storageUploadStore = useStorageUploadStore();
const {
  isFileViewModeCard,
  searchKeyword,
  selectedFolder,
  fileList,
  folderList,
  isLoadingFile,
  filterParam
} = storeToRefs(storageUploadStore);

const searchInput = ref('');
const filterDataStorageOptions = [
  {
    value: STORAGE_UPLOAD_FILTER_VALUE.NEWEST,
    label: 'studio.prj_prod_mngmt.storage_srch_sorting_value1'
  },
  {
    value: STORAGE_UPLOAD_FILTER_VALUE.OLDEST,
    label: 'studio.prj_prod_mngmt.storage_srch_sorting_value2'
  },
  {
    value: STORAGE_UPLOAD_FILTER_VALUE.ASCENDING,
    label: 'studio.prj_prod_mngmt.storage_srch_sorting_value3'
  },
  {
    value: STORAGE_UPLOAD_FILTER_VALUE.DESCENDING,
    label: 'studio.prj_prod_mngmt.storage_srch_sorting_value4'
  }
];
const valueFilterDataStorage = ref(filterDataStorageOptions[0].value);

const sortConstant = STORAGE_UPLOAD_SORT_OPTIONS;
const directionConstant = SORT_DIRECTION;

const isChooseViewModeCard = () => {
  if (isFileViewModeCard.value) {
    return;
  }
  isFileViewModeCard.value = true;
};

const isChooseViewModeList = () => {
  if (!isFileViewModeCard.value) {
    return;
  }
  isFileViewModeCard.value = false;
};

const onSearch = () => {
  if (searchInput.value) {
    searchKeyword.value = searchInput.value;
    storageUploadStore.setSearchTextFolderParams(searchKeyword?.value);
    storageUploadStore.setSearchTextFileParams(searchKeyword?.value);
  } else {
    searchKeyword.value = '';
    storageUploadStore.setSearchTextFolderParams();
    storageUploadStore.setSearchTextFileParams();
  }
  storageUploadStore.fetchFiles();
  storageUploadStore.fetchFolder();
};

const handleFilterDataChange = () => {
  filterParam.value = valueFilterDataStorage.value;
  switch (valueFilterDataStorage.value) {
    case STORAGE_UPLOAD_FILTER_VALUE.NEWEST:
      storageUploadStore.setSortDirectionFileParams(
        sortConstant.CREATED_AT,
        directionConstant.DESC
      );
      storageUploadStore.setSortDirectionFolderParams(
        sortConstant.CREATED_AT,
        directionConstant.DESC
      );
      break;
    case STORAGE_UPLOAD_FILTER_VALUE.OLDEST:
      storageUploadStore.setSortDirectionFileParams(sortConstant.CREATED_AT, directionConstant.ASC);
      storageUploadStore.setSortDirectionFolderParams(
        sortConstant.CREATED_AT,
        directionConstant.ASC
      );
      break;
    case STORAGE_UPLOAD_FILTER_VALUE.ASCENDING:
      storageUploadStore.setSortDirectionFileParams(
        sortConstant.USER_FILE_NAME,
        directionConstant.ASC
      );
      storageUploadStore.setSortDirectionFolderParams(
        sortConstant.DIRECTORY_NAME,
        directionConstant.ASC
      );
      break;
    case STORAGE_UPLOAD_FILTER_VALUE.DESCENDING:
      storageUploadStore.setSortDirectionFileParams(
        sortConstant.USER_FILE_NAME,
        directionConstant.DESC
      );
      storageUploadStore.setSortDirectionFolderParams(
        sortConstant.DIRECTORY_NAME,
        directionConstant.DESC
      );
      break;
  }
  storageUploadStore.fetchFiles();
  storageUploadStore.fetchFolder();
};
</script>
