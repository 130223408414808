<!--StorageFileListItemSkeleton-->
<template>
  <div class="w-full py-[.6rem] pl-20 pr-[1rem] rounded-xl">
    <div class="flex items-center gap-8 animate-pulse">
      <s-skeleton class="shrink-0 w-16 h-16 mr-12 bg-inverse-elevation-5 rounded-sm" />
      <s-skeleton class="shrink-0 w-36 h-36 bg-inverse-elevation-5 rounded-md" />

      <div class="flex-1 pr-[7.6rem]">
        <s-skeleton class="w-full h-[1.8rem] bg-inverse-elevation-5 rounded-md" />
      </div>
      <s-skeleton class="shrink-0 w-80 h-[1.8rem] mr-8 bg-inverse-elevation-5 rounded-md" />
      <s-skeleton class="shrink-0 w-120 h-[1.8rem] mr-[5.2rem] bg-inverse-elevation-5 rounded-md" />
    </div>
  </div>
</template>
