import { defineStore } from 'pinia';
import { axios } from 'seed-core';
import { getCurrentInstance, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  checkStorageUsageApi,
  confirmAllFilesOnSessionApi,
  createFolderApi,
  deleteFilesApi,
  deleteFolderApi,
  fetchFileListApi,
  fetchFolderListApi,
  moveFilesToNewDirectoryApi,
  updateFolderNameApi,
  uploadFileApiStorage
} from '@/apis/storage-upload.api';
import StoragePopupMoveFolder from '@/components/storage/modal/storage-popup-move-folder.vue';
import StoragePopupRegisterFolder from '@/components/storage/modal/storage-popup-register-folder.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { HttpError } from '@/composables/useRequest';
import { COMMON_ERROR_MESSAGE_KEY, STATUS_CODE } from '@/constants/error.const';
import { ARRAY_IMAGE_FILE_EXTENSION, ARRAY_VIDEO_FILE_EXTENSION } from '@/constants/file.const';
import { ROLE_ID } from '@/constants/permission.const';
import {
  INITIAL_STORAGE_UPLOAD_FILE_PARAMS,
  INITIAL_STORAGE_UPLOAD_FOLDER_PARAMS,
  REGISTER_EDIT_SUBMIT_MODE,
  STORAGE_UPLOAD_FILE_DEFAULT_PAGE
} from '@/constants/storage-upload.const';
import type {
  StoragePopupRegisterFolderEmits,
  StorageUploadDirectoryType,
  StorageUploadFileType,
  StorageUploadUsageType
} from '@/types/storage-upload/storage-upload-file.type';
import type {
  GetFileListRequest,
  GetFolderListRequest,
  UploadFileRequestParams
} from '@/types/storage-upload/storage-upload-request.type';
import { showCommonErrorDialog } from '@/utils/common.util';
import { generateUUID } from '@/utils/uuid.util';

export const useStorageUploadStore = defineStore('storageUploadStore', () => {
  const { t } = useI18n();
  const app = useApp();

  const fileList = ref<StorageUploadFileType[]>([]);
  const totalElementsFileList = ref<number>(0);
  const folderList = ref<StorageUploadDirectoryType[]>([]);
  const selectedFolder = ref<StorageUploadDirectoryType | null>();
  const selectedFiles = ref<StorageUploadFileType[]>([]);
  const storageUsageData = ref<StorageUploadUsageType>({
    capacityInBytes: 1,
    usedInBytes: 0,
    projectName: ''
  });
  const searchKeyword = ref<string>('');
  const projectId = ref<string>('');
  const isFileViewModeCard = ref<boolean>(true);
  const isLoadingFile = ref<boolean>(false);
  const isAttachedFile = ref<boolean>(false);
  const canLoadMoreFile = ref<boolean>(true);
  const acceptFileType = ref<string[]>([
    ...ARRAY_IMAGE_FILE_EXTENSION,
    ...ARRAY_VIDEO_FILE_EXTENSION
  ]);
  const cancelTokenSource = ref<any>(axios.CancelToken.source());
  const proxy: any = getCurrentInstance()?.proxy;
  const uploadUUID = ref<string>('');
  const filterParam = ref<string>('');

  const route = useRoute();

  let fetchFileParams: GetFileListRequest = INITIAL_STORAGE_UPLOAD_FILE_PARAMS;
  let fetchFolderParams: GetFolderListRequest = INITIAL_STORAGE_UPLOAD_FOLDER_PARAMS;

  const fetchStorageUsage = async (showLoading: boolean = true, params?: { projectId: string }) => {
    try {
      isLoadingFile.value = true;
      const data = await checkStorageUsageApi(params?.projectId || projectId.value, showLoading);
      if (data) {
        storageUsageData.value = data;
      }
      isLoadingFile.value = false;
    } catch (err) {
      // console.error(err);
    }
  };

  const fetchFolder = async (showLoading: boolean = true) => {
    try {
      const data = await fetchFolderListApi(projectId.value, fetchFolderParams, showLoading);
      folderList.value = data?.contents || [];
    } catch (err) {
      // console.error(err);
    }
  };

  const setSortDirectionFolderParams = (sort: string, direction: string) => {
    fetchFolderParams = {
      ...fetchFolderParams,
      sort,
      direction
    };
  };

  const setSearchTextFolderParams = (searchText: string | undefined = undefined) => {
    fetchFolderParams = {
      ...fetchFolderParams,
      q: searchText
    };
  };

  const deleteFolderItem = async (directory?: StorageUploadDirectoryType, callback?: any) => {
    const result = await showConfirm({
      content: t('studio.prj_prod_mngmt.storage_only_folder.del_folder_popup_msg'),
      confirmLabel: t('studio.prj_prod_mngmt.storage_only_folder.btn_del_folder_cf'),
      cancelVariant: 'outline',
      confirmVariant: 'red'
    });
    if (!(await app.checkMenuWritable())) {
      return;
    }
    if (result && directory) {
      try {
        const item = await deleteFolderApi(projectId.value, directory.directoryNo);
        await fetchStorageUsage();
        if (item) {
          await fetchFolder();
          if (callback) {
            callback();
          }
        } else {
          // TODO: show when call api error (folder not exists)
          await showAlertSeverError();
          await fetchFolder();
        }
      } catch (err: any) {
        await showPopupErrorFunc(err);
      }
    }
  };

  const createOrEditFolderItem = async (currentFolder?: StorageUploadDirectoryType) => {
    if (!(await app.checkMenuWritable())) {
      return;
    }
    const data: StoragePopupRegisterFolderEmits | null | undefined = await showDialog({
      component: shallowRef(StoragePopupRegisterFolder),
      isOverlayDialog: true,
      props: {
        currentFolder: currentFolder || null,
        isNew: !currentFolder
      }
    });

    if (data) {
      if (!(await app.checkMenuWritable())) {
        return;
      }
      const { type, folderName } = data;
      if (type === REGISTER_EDIT_SUBMIT_MODE.CREATE) {
        await createFolderItem(folderName);
      } else if (type === REGISTER_EDIT_SUBMIT_MODE.EDIT) {
        await editFolderItem(currentFolder!, folderName);
      }
      await fetchFolder();
    }
  };

  const createFolderItem = async (newFolderName: string) => {
    try {
      const item = await createFolderApi(projectId.value, newFolderName);
      if (item) {
        folderList.value.unshift(item);
      }
    } catch (err: any) {
      await showPopupErrorFunc(err);
    }
  };

  const editFolderItem = async (
    currentlyFolder: StorageUploadDirectoryType,
    newDirectoryName: string
  ) => {
    try {
      const directory = await updateFolderNameApi(
        projectId.value,
        currentlyFolder.directoryNo,
        newDirectoryName
      );
      if (directory) {
        currentlyFolder.directoryName = directory?.directoryName;
      }
    } catch (err: any) {
      await showPopupErrorFunc(err);
    }
  };

  const fetchFiles = async (showLoading: boolean = true) => {
    if (canLoadMoreFile.value) {
      isLoadingFile.value = true;
      const data = await fetchFileListApi(projectId.value, fetchFileParams, showLoading);
      if (data) {
        canLoadMoreFile.value = data.contents.length === fetchFileParams.size;
        totalElementsFileList.value = data.totalElements;
        if (fetchFileParams.page === 1) {
          fileList.value = data.contents || [];
        } else {
          fileList.value = fileList.value.concat(data.contents);
        }
      } else {
        canLoadMoreFile.value = false;
      }
    }
    await fetchStorageUsage(showLoading);
    isLoadingFile.value = false;
  };

  const loadMorePageFileParams = () => {
    setPageFileParams(fetchFileParams.page + 1);
  };

  const setPageFileParams = (page: number) => {
    fetchFileParams = {
      ...fetchFileParams,
      page
    };
    if (page === 1) {
      canLoadMoreFile.value = true;
    }
  };

  const setDirectoryNoFileParams = (directoryNo?: number | undefined) => {
    fetchFileParams = {
      ...fetchFileParams,
      directoryNo,
      page: STORAGE_UPLOAD_FILE_DEFAULT_PAGE
    };
    canLoadMoreFile.value = true;
  };

  const setSortDirectionFileParams = (sort: string, direction: string) => {
    fetchFileParams = {
      ...fetchFileParams,
      sort,
      direction,
      page: STORAGE_UPLOAD_FILE_DEFAULT_PAGE
    };
    canLoadMoreFile.value = true;
  };

  const setSearchTextFileParams = (searchText: string | undefined = undefined) => {
    fetchFileParams = {
      ...fetchFileParams,
      q: searchText,
      page: STORAGE_UPLOAD_FILE_DEFAULT_PAGE
    };
    canLoadMoreFile.value = true;
  };

  const moveFiles = async (files: StorageUploadFileType[]) => {
    const data = await fetchFolderListApi(projectId.value, INITIAL_STORAGE_UPLOAD_FOLDER_PARAMS);
    if (!data?.contents?.length) {
      await showAlert({
        content: t('studio.storage.no_folder_available.cannot_move_file_msg')
      });
      return;
    }
    const result: StorageUploadDirectoryType = await showDialog({
      component: shallowRef(StoragePopupMoveFolder),
      isOverlayDialog: true,
      props: {
        folderList: data?.contents ?? folderList.value,
        currentFolder: selectedFolder.value || null
      }
    });
    if (!(await app.checkMenuWritable())) {
      return;
    }
    if (result) {
      try {
        const data = await moveFilesToNewDirectoryApi(
          projectId.value,
          files.map((file: StorageUploadFileType) => file.fileId),
          result.directoryNo
        );
        if (data) {
          proxy?.$SToast({
            text: t('studio.prj_prod_mngmt.storage_upload_done.move_completed_msg'),
            duration: 1200
          });
          selectedFiles.value = [];
          await fetchFiles();
          await fetchFolder();
          return true;
        }
      } catch (err: any) {
        await showPopupErrorFunc(err);
      }
    }
  };

  const removeFile = async (files: StorageUploadFileType[]) => {
    const result = await showConfirm({
      content: t('studio.prj_prod_mngmt.storage_multiple_select.del_all_msg', {
        fileNum: files.length
      }),
      cancelVariant: 'outline',
      confirmVariant: 'red',
      confirmLabel: t('studio.prj_prod_mngmt.storage_upload_done.popup_del_cf_btn')
    });
    if (!(await app.checkMenuWritable())) {
      return;
    }
    if (result) {
      try {
        const deleteCount = await deleteFilesApi(
          projectId.value,
          files.map((file: StorageUploadFileType) => file.fileId)
        );
        if (deleteCount) {
          await fetchDataReset();
          if (files.length === 1) {
            proxy?.$SToast({
              text: t('studio.prj_prod_mngmt.storage_upload_done.del_completed_msg'),
              duration: 1200
            });
          } else {
            proxy?.$SToast({
              text: t('studio.prj_prod_mngmt.storage_multiple_select.del_all_done_msg', {
                fileNum: files.length
              }),
              duration: 1200
            });
          }
        } else {
          setPageFileParams(1);
          await showAlertSeverError();
          await fetchFiles();
        }
      } catch (err: any) {
        await showPopupErrorFunc(err);
      }
      await fetchStorageUsage();
    }
  };

  const uploadFiles = async (
    files: File[],
    params?: { projectId: string }
  ): Promise<{ newFileCount: number; failValues: any[]; successValues: any[] }> => {
    const { meta } = route;
    const roleAuthorityId = meta.roleAuthorityId as string;
    const arrRequest = [];
    const curProjectId: string = params?.projectId || projectId.value;
    uploadUUID.value = `${curProjectId}_${generateUUID()}`;
    for (let x = 0; x < files.length; x++) {
      const paramsRequest: UploadFileRequestParams = {
        directoryNo: selectedFolder.value?.directoryNo || 0,
        sessionId: uploadUUID.value,
        totalCount: files.length,
        showGlobalLoading: false
      };
      if (
        ROLE_ID.BANNER_IMAGE === roleAuthorityId ||
        ROLE_ID.PRODUCT_RUN_OPTION === roleAuthorityId ||
        ROLE_ID.PRODUCT_STORE_PAGE === roleAuthorityId
      ) {
        delete paramsRequest.sessionId;
        delete paramsRequest.totalCount;
        paramsRequest.showGlobalLoading = true;
      }
      const file = files[x];
      arrRequest.push(uploadFileApiStorage(curProjectId, file, paramsRequest));
    }
    const results = await Promise.allSettled(arrRequest);
    const successValues: StorageUploadFileType[] = [];
    const failValues: any[] = [];
    results.forEach((result: PromiseSettledResult<any>) => {
      if (result.status === 'fulfilled') {
        successValues.push(result.value as StorageUploadFileType);
      } else {
        failValues.push(result.reason);
      }
    });
    return {
      newFileCount: successValues.length && !failValues.length ? successValues.length : 0,
      failValues,
      successValues
    };
  };

  const confirmAllFilesOnSession = async (
    projectId: string,
    sessionId: string
  ): Promise<boolean | undefined> => {
    return await confirmAllFilesOnSessionApi(projectId, sessionId);
  };

  const resetDataInternal = () => {
    fileList.value = [];
    folderList.value = [];
    selectedFiles.value = [];
    storageUsageData.value = {
      capacityInBytes: 0,
      usedInBytes: 0,
      projectName: ''
    };
    cancelTokenSource.value = axios.CancelToken.source();
    totalElementsFileList.value = 0;
  };

  const resetData = () => {
    resetDataInternal();
    uploadUUID.value = '';
    projectId.value = '';
    isFileViewModeCard.value = true;
    acceptFileType.value = [];
    isAttachedFile.value = false;
    selectedFolder.value = null;
    searchKeyword.value = '';
    fetchFileParams = INITIAL_STORAGE_UPLOAD_FILE_PARAMS;
    fetchFolderParams = INITIAL_STORAGE_UPLOAD_FOLDER_PARAMS;
    canLoadMoreFile.value = true;
    isLoadingFile.value = false;
    filterParam.value = '';
  };

  const showPopupErrorFunc = async (errors: HttpError) => {
    await showCommonErrorDialog(
      COMMON_ERROR_MESSAGE_KEY,
      errors.statusCode === STATUS_CODE.FILE_NOT_FOUND ||
        errors.statusCode === STATUS_CODE.DIRECTORY_NOT_FOUND ||
        errors.statusCode === STATUS_CODE.DUPLICATED_DIRECTORY_NAME
        ? fetchDataReset
        : undefined
    );
  };

  const fetchDataReset = async (showLoading: boolean = true) => {
    resetDataInternal();
    setPageFileParams(1);
    try {
      await Promise.all([fetchFiles(showLoading), fetchFolder(showLoading)]);
    } catch (err) {
      // console.error(err);
    }
  };

  const showAlertSeverError = async () => {
    await showAlert({
      content: t('studio.business_bank_setting.individual.enter_no_msg3')
    });
  };

  return {
    fileList,
    folderList,
    storageUsageData,
    selectedFolder,
    selectedFiles,
    searchKeyword,
    projectId,
    isFileViewModeCard,
    isLoadingFile,
    isAttachedFile,
    canLoadMoreFile,
    cancelTokenSource,
    totalElementsFileList,
    acceptFileType,
    uploadUUID,
    filterParam,
    // method for storage
    fetchStorageUsage,
    // method for folders
    fetchFolder,
    deleteFolderItem,
    createOrEditFolderItem,
    createFolderItem,
    editFolderItem,
    setSortDirectionFolderParams,
    setSearchTextFolderParams,
    // method for files
    fetchFiles,
    setPageFileParams,
    setSortDirectionFileParams,
    setDirectoryNoFileParams,
    setSearchTextFileParams,
    loadMorePageFileParams,
    moveFiles,
    resetData,
    removeFile,
    uploadFiles,
    confirmAllFilesOnSession,
    resetDataInternal,
    fetchDataReset
  };
});
