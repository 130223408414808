import { COMMON_MIME_TYPES, FILES_TO_IGNORE } from '@/constants/file-selector.const';
import type { FileArray, FileValue, FileWithPath } from '@/types/storage-upload/file-selector.type';

function withMimeType(file: FileWithPath) {
  const { name } = file;
  const hasExtension = name && name.lastIndexOf('.') !== -1;

  if (hasExtension && !file.type) {
    const ext = name.split('.').pop()!.toLowerCase();
    const type = COMMON_MIME_TYPES.get(ext);
    if (type) {
      Object.defineProperty(file, 'type', {
        value: type,
        writable: false,
        configurable: false,
        enumerable: true
      });
    }
  }

  return file;
}

export function toFileWithPath(file: FileWithPath, path?: string): FileWithPath {
  const f = withMimeType(file);
  if (typeof f.path !== 'string') {
    // on electron, path is already set to the absolute path
    const { webkitRelativePath } = file as any;
    Object.defineProperty(f, 'path', {
      value:
        typeof path === 'string'
          ? path
          : typeof webkitRelativePath === 'string' && webkitRelativePath.length > 0
            ? webkitRelativePath
            : file.name,
      writable: false,
      configurable: false,
      enumerable: true
    });
  }

  return f;
}

async function getDataTransferFiles(dt: DataTransfer, type: string) {
  // IE11 does not support dataTransfer.items
  // See https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/items#Browser_compatibility
  if (dt.items) {
    const items = fromList<DataTransferItem>(dt.items).filter((item: any) => item.kind === 'file');
    // According to https://html.spec.whatwg.org/multipage/dnd.html#dndevents,
    // only 'dragstart' and 'drop' has access to the data (source node)
    if (type !== 'drop') {
      return items;
    }
    const files = await Promise.all(items.map(toFilePromises));
    return noIgnoredFiles(flatten<FileWithPath>(files));
  }

  return noIgnoredFiles(
    fromList<FileWithPath>(dt.files).map((file: FileWithPath) => toFileWithPath(file))
  );
}

function getInputFiles(evt: Event) {
  const files = isInput(evt.target)
    ? evt.target.files
      ? fromList<FileWithPath>(evt.target.files)
      : []
    : [];
  return files.map((file: any) => toFileWithPath(file));
}

function isInput(value: EventTarget | null): value is HTMLInputElement {
  return value !== null;
}

function isDragEvt(value: any): value is DragEvent {
  return !!value.dataTransfer;
}

function noIgnoredFiles(files: FileWithPath[]) {
  // eslint-disable-next-line
  return files.filter((file: FileWithPath) => FILES_TO_IGNORE.indexOf(file.name) === -1);
}

function fromList<T>(items: DataTransferItemList | FileList): T[] {
  const files = [];

  // tslint:disable: prefer-for-of
  for (let i = 0; i < items.length; i++) {
    const file = items[i];
    files.push(file);
  }

  return files as any;
}

function flatten<T>(items: any[]): T[] {
  return items.reduce(
    (acc: any, files: any) => [...acc, ...(Array.isArray(files) ? flatten(files) : [files])],
    []
  );
}

function toFilePromises(item: DataTransferItem) {
  if (typeof item.webkitGetAsEntry !== 'function') {
    return fromDataTransferItem(item);
  }

  const entry = item.webkitGetAsEntry();

  // Safari supports dropping an image node from a different window and can be retrieved using
  // the DataTransferItem.getAsFile() API
  // NOTE: FileSystemEntry.file() throws if trying to get the file
  if (entry && entry.isDirectory) {
    return fromDirEntry(entry) as any;
  }

  return fromDataTransferItem(item);
}

function fromDataTransferItem(item: DataTransferItem) {
  const file = item.getAsFile();
  if (!file) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(`${item} is not a File`);
  }
  const fwp = toFileWithPath(file);
  return Promise.resolve(fwp);
}

function fromDirEntry(entry: any) {
  const reader = entry.createReader();

  return new Promise<FileArray[]>((resolve: any, reject: any) => {
    const entries: Promise<FileValue[]>[] = [];

    function readEntries() {
      // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryEntry/createReader
      // https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryReader/readEntries
      reader.readEntries(
        async (batch: any[]) => {
          if (!batch.length) {
            // Done reading directory
            try {
              const files = await Promise.all(entries);
              resolve(files);
            } catch (err) {
              reject(err);
            }
          } else {
            const items = Promise.all(batch.map(fromEntry));
            entries.push(items);

            // Continue reading
            readEntries();
          }
        },
        (err: any) => {
          reject(err);
        }
      );
    }

    readEntries();
  });
}

async function fromEntry(entry: any) {
  return entry.isDirectory ? fromDirEntry(entry) : fromFileEntry(entry);
}

async function fromFileEntry(entry: any) {
  return new Promise<FileWithPath>((resolve: any, reject: any) => {
    entry.file(
      (file: FileWithPath) => {
        const fwp = toFileWithPath(file, entry.fullPath);
        resolve(fwp);
      },
      (err: any) => {
        reject(err);
      }
    );
  });
}

export async function fromEvent(evt: Event): Promise<(FileWithPath | DataTransferItem)[]> {
  return isDragEvt(evt) && evt.dataTransfer
    ? getDataTransferFiles(evt.dataTransfer, evt.type)
    : getInputFiles(evt);
}
